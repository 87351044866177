import React from 'react';
import { Link } from 'gatsby';
import BasicLink from './BasicLink';

interface AnchorLinkProps {
  className?: string;
  href: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export function AnchorLink(
  {
    href,
    className = '',
    children,
    style,
  }: AnchorLinkProps,
): JSX.Element {
  const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const id = href.slice(1);
    const anchor = document.getElementById(id);
    if (anchor) {
      const offsetTop = anchor.getBoundingClientRect().top + window.pageYOffset;
      window.scroll({
        top: offsetTop - 128,
        behavior: 'smooth',
      });
      event.preventDefault();
    }
  };
  return (
    <BasicLink to={href} onClick={onClick} className={className} style={style}>
      {children}
    </BasicLink>
  );
}

export interface ProductsLandingSectionProps {
  className?: string;
  title: React.ReactChild;
  bodyText?: React.ReactNode;
  image: JSX.Element;
  imageUrl: string;
  imageAlt?: string;
  wideText?: boolean;
  withTryButton?: boolean;
  tryButtonText?: string;
  secondaryButton?: {
    label: string;
    link: string;
  }
}

export default function ProductsLandingSection(
  {
    className = '',
    title,
    image,
    imageUrl,
    imageAlt = 'CAD Exchanger Products',
    bodyText = (
      <>
        Applications for <AnchorLink href="/products/gui/">end-users</AnchorLink>.
        SDK&apos;s and tools for <AnchorLink href="/products/sdk/">software developers</AnchorLink>.
        Custom development services for <AnchorLink href="/custom-development/">businesses</AnchorLink>.
      </>
    ),
    wideText,
    withTryButton = false,
    tryButtonText = 'Try it for free',
    secondaryButton = {
      label: 'Explore products',
      link: '/products/',
    },
  }: ProductsLandingSectionProps,
): JSX.Element {
  return (
    <section className={`pt-6 pb-8 pt-lg-11 pb-lg-10 ${className}`}>
      <div className="container">
        <div className="row align-items-stretch">
          <div className={`col-12 ${wideText ? 'col-lg-5' : 'col-lg-7 offset-xxl-1'} order-lg-last`}>
            <div className="mw-100 mt-n2 mt-lg-n4 mb-n4 mb-lg-n7 d-lg-none">{image}</div>
            <img src={imageUrl} className="mw-100 mt-n2 mt-lg-n4 mb-n4 mb-lg-n7 d-none d-lg-block" alt={imageAlt} title={imageAlt} loading="eager" />
          </div>
          <div className={`col-12 col-lg-5 ${wideText ? 'col-xl-7' : 'col-xxl-4'} mt-6 mt-md-0`}>
            <h1 className={`mb-4 ${wideText ? 'h2' : 'mb-md-6'}`}>
              {title}
            </h1>
            <p className="mr-md-6 mr-xxl-0">
              {bodyText}
            </p>
            <div className="mt-6 mb-n4 mx-n2">
              {withTryButton && (
              <Link to="/products/gui/try/" className="btn btn-primary px-4 mx-2 mb-4" style={{ width: '11rem' }}>
                {tryButtonText}
              </Link>
              )}
              <AnchorLink href={secondaryButton.link} className={`btn btn-${withTryButton ? 'secondary' : 'primary'} px-4 mx-2 mb-4`} style={{ minWidth: '11rem' }}>
                {secondaryButton.label}
              </AnchorLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
