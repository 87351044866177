import React from 'react';
// import { Link } from 'gatsby';
import BasicLink, { BasicLinkProps } from '../BasicLink';

import FlatArrowIcon from '../../img/icons/flat-arrow.svg';
import './LinkWithFlatArrow.scss';

export interface LinkWithFlatArrowProps extends BasicLinkProps {
  fontSize?: 'sm' | 'normal' | 'lg';
}

export default function LinkWithFlatArrow(
  {
    to,
    target,
    children,
    className = '',
    fontSize = 'normal',
    style,
  }: LinkWithFlatArrowProps,
): JSX.Element {
  return (
    <BasicLink to={to} target={target} className={`link-with-flat-arrow font-size-${fontSize} d-flex align-items-center justify-content-start text-decoration-none ${className}`} style={{ ...style, whiteSpace: 'nowrap' }}>
      <span className="mr-2 bold-hover-fix" data-text-css={typeof children === 'string' ? children : ''} style={{ userSelect: 'none', whiteSpace: 'normal' }}>{children}</span><FlatArrowIcon />
    </BasicLink>
  );
}
