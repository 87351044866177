/* eslint-disable import/prefer-default-export */

export interface Product {
  internalName: string;
  shortName: string;
  name: string;
  shortDescription: string;
  description: string;
  type?: 'for-end-users' | 'dev-tool';
  url: string;
  logoUrl: string;
  docs?: string;
  youtubePlaylist?: string;
}

const gui: Product = {
  internalName: 'gui',
  shortName: 'Lab',
  name: 'CAD Exchanger Lab',
  shortDescription: 'Desktop application to view, analyze and convert 3D\xa0data',
  description: 'Desktop application to view, explore and convert 3D CAD data across 30+ file formats.',
  type: 'for-end-users',
  url: '/products/gui/',
  logoUrl: '',
};

const cloud: Product = {
  internalName: 'cloud',
  shortName: 'Cloud',
  name: 'CAD Exchanger Cloud',
  shortDescription: 'Securely view, share, and communicate through the browser',
  description: 'Online platform to securely view, share and collaborate on 3D files through the browser.',
  type: 'for-end-users',
  url: '/products/cloud/',
  logoUrl: '',
};

const sdk: Product = {
  internalName: 'sdk',
  shortName: 'SDK',
  name: 'CAD Exchanger SDK',
  shortDescription: 'C++, C#, Java, Python libraries for apps and SaaS',
  description: 'Visualize interactive 3D CAD in your engineering software for desktop, web and mobile.',
  type: 'dev-tool',
  url: '/products/sdk/',
  logoUrl: '',
  docs: 'https://docs.cadexchanger.com/sdk/',
  youtubePlaylist: 'https://www.youtube.com/playlist?list=PLgAY6fhR-6_2e5H0INzTpzdnfANvQZNqa',
};

const cli: Product = {
  internalName: 'cli',
  shortName: 'Batch',
  name: 'CAD Exchanger Batch',
  shortDescription: 'Command line for batch conversions',
  description: 'Light-weight command-line tool for batch conversions in corporate or cloud environments.',
  type: 'dev-tool',
  url: '/products/cli/',
  logoUrl: '',
  docs: 'https://docs.cadexchanger.com/cli/',
  youtubePlaylist: 'https://www.youtube.com/playlist?list=PLgAY6fhR-6_0rWRUJpuxdVZ868jkQQdxw',
};

const wtk: Product = {
  internalName: 'web-toolkit',
  shortName: 'Web Toolkit',
  name: 'CAD Exchanger Web Toolkit',
  shortDescription: 'Javascript libraries for web\xa0apps',
  description: 'Javascript libraries to visualize 3D\xa0CAD in web\xa0apps.',
  type: 'dev-tool',
  url: '/products/sdk/add-ons/web-toolkit/',
  logoUrl: '',
  docs: 'https://docs.cadexchanger.com/web-toolkit/',
};

const cloudApi: Product = {
  internalName: 'cloud-api',
  shortName: 'Cloud API',
  name: 'CAD Exchanger Cloud API',
  shortDescription: 'REST API for conversions in\xa0the\xa0cloud',
  description: 'REST API to securely convert CAD files in the cloud without any infrastructure deployment.',
  type: 'dev-tool',
  url: '/products/cloud-api/',
  logoUrl: '',
  docs: 'https://docs.cadexchanger.com/cloud-api/',
};

const customDev: Product = {
  internalName: 'dev',
  shortName: 'Custom Dev',
  name: 'Custom Development Services',
  shortDescription: 'From short-term to multi-year projects, we can develop a turn-key solution for you.',
  description: 'From short-term to multi-year projects, we can develop a turn-key solution for you.',
  url: '/custom-development/',
  logoUrl: '',
};

class ProductsList extends Array<Product> {
  gui: Product;
  cloud: Product;
  sdk: Product;
  cli: Product;
  wtk: Product;
  cloudApi: Product;
  customDev: Product;

  forEndUsers: Product[];
  devTools: Product[];

  constructor() {
    super(gui, cloud, sdk, cli, wtk, cloudApi);
    this.gui = gui;
    this.cloud = cloud;
    this.sdk = sdk;
    this.cli = cli;
    this.wtk = wtk;
    this.cloudApi = cloudApi;
    this.customDev = customDev;

    this.forEndUsers = [gui];
    this.devTools = [sdk, cli];
  }
}

export default new ProductsList();
