/* eslint-disable import/prefer-default-export */
import { FeaturesCarouselProps } from 'cadex-gatsby-theme/src/components/products/FeaturesCarousel';
import conversion from '../img/case-cards/16by10/conversion.png';
import data from '../img/case-cards/16by10/3ddata.png';
import measure from '../img/case-cards/16by10/measure.png';
import pmi from '../img/case-cards/16by10/pmi.png';
import exploded from '../img/case-cards/16by10/exploded.png';
import optim from '../img/case-cards/16by10/optim.png';

export const ruFeatures: FeaturesCarouselProps['slides'] = [
  {
    title: 'Импорт и экспорт',
    navTitle: 'Импорт и экспорт',
    bodyText: 'Чтение и запись данных в более чем 30 форматах универсальных и архитектурно-строительных САПР. Работа с проприетарными форматами (Solidworks, CATIA, NX и Creo) без необходимости в дорогостоящем ПО. Импорт и экспорт нейтральных форматов (STEP, JT, glTF, FBX) и форматов геометрических ядер (ACIS, Parasolid) для дальнейшей работы с продуктом в специализированных программах.',
    image: conversion,
  },
  {
    title: 'Доступ к 3D-данным и чертежам',
    navTitle: 'Доступ к 3D-данным и чертежам',
    bodyText: 'Унифицированная модель данных обеспечивает доступ ко всем 3D-данным и чертежам независимо от формата файлов и их источника. Система позволяет исследовать иерархию сборок, перемещаться по всем листам, видам и слоям чертежей, изучать все сущности точной и полигональной геометрии и извлекать содержимое чертежей (2D-кривые, размеры, штриховки, примечания и многое другое).',
    image: data,
  },
  {
    title: 'Анализ и измерения',
    navTitle: 'Анализ и измерения',
    bodyText: 'Анализ геометрии граней и рёбер, извлечение свойств и типов поверхностей и кривых. Вычисление длин кривых и локальных характеристик кривых и поверхностей: касательных, нормалей и кривизны. Связь 3D и параметрических кривых для реализации сложных алгоритмов обработки точной геометрии. Расчёт ограничивающего параллелепипеда, центра масс, объёма и площади поверхностей моделей для оценки их целостности или обработки клиентским приложением.',
    image: measure,
  },
  {
    title: 'Доступ к PMI и метаданным',
    navTitle: 'Доступ к PMI и метаданным',
    bodyText: 'Извлечение и запись такой концептуальной информации, как имена, идентификаторы и метаданные, назначенные деталям, сборкам или геометрии. Доступ к PMI-аннотациям (размеры, допуски, рамка символов с геометрическими требованиями) в графической или машиночитаемой форме. Создание алгоритмов производства благодаря доступу к граням и рёбрам, которым назначены аннотации PMI. Создание машиночитаемых PMI-аннотации и их экспорт в STEP и JT для дальнейшего использования.',
    image: pmi,
  },
  {
    title: 'Построение сечений и видов с разнесёнными деталями',
    navTitle: 'Построение сечений и видов с разнесёнными деталями',
    bodyText: 'Анализ конструкции многокомпонентных сборок со сложной иерархией. Построение сечений в нескольких плоскостях или развертки компонентов сборки для просмотра разнесённых деталей модели. Экспорт полученных видов для демонстрации проекта, а также иллюстраций в каталоги деталей, руководства по сборке, обслуживанию и других обучающих материалов.',
    image: exploded,
  },
  {
    title: 'Генерация сеток и оптимизация моделей',
    navTitle: 'Генерация сеток и оптимизация моделей',
    bodyText: 'Генерация высококачественных полигональных сеток из точной геометрии. Управление степенью детализации сетки с помощью удобных пресетов или точной настройки отдельных параметров. Редактирование моделей для объединения существующих объектов или создания новых. Генерация примитивных тел, операции смещения и вращения, создание произвольно сложных B-Rep-тел (от вершины к твердому телу).',
    image: optim,
  },

];
