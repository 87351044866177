import React from 'react';

export interface HoveredLogoProps {
  className?: string;
  logo: string;
  bluedOutLogo: string;
  alt: string;
  active: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export default function HoveredLogo(
  {
    className = '',
    logo,
    bluedOutLogo,
    alt,
    active,
    onClick,
  }: HoveredLogoProps,
): JSX.Element {
  const [isHovered, setIsHovered] = React.useState(false);
  return (
    <button
      type="button"
      style={{ backgroundColor: 'transparent', border: 'none', maxHeight: 32 }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => (setIsHovered(false))}
      onClick={onClick}
      className={className}
    >
      <img alt={alt} className="h-100" src={isHovered || active ? logo : bluedOutLogo} />
    </button>
  );
}
