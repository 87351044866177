import React from 'react';

import './ProductCard.scss';

export interface ProductCardProps {
  name: string;
  description: string;
  color: string;
  image: string;
  vertical?: boolean;
  className?: string;
  children: React.ReactNode;
  subTitle?: string;
  shifted?: boolean;
  smallPadding?: true;
}

export default function ProductCard(
  {
    name,
    description,
    color,
    image,
    children,
    vertical,
    className,
    subTitle,
    shifted,
    smallPadding,
  }: ProductCardProps,
): JSX.Element {
  return (
    <div className={`product-card card shadow-light h-100 overflow-hidden ${className}`}>
      <div className={`card-border-left bg-${color}`} />
      <div className={`card-body d-flex flex-column flex-fill mw-70 ${vertical ? 'mw-md-100' : ''} ${smallPadding ? 'pb-md-5' : 'pb-md-11'}`}>
        <h3 className={`${subTitle ? 'mb-1' : ''}`}>{name}</h3>
        {subTitle && (<p className="font-size-sm mb-1">{subTitle}</p>)}
        <p className={`${subTitle ? 'text-black' : ''}`}>{description}</p>
        {children}
      </div>
      <img src={image} alt={name} title={name} className={`pseudo-bg ${shifted ? 'shifted' : ''}`} loading="lazy" />
    </div>
  );
}
