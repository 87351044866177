import React from 'react';

import './Footer.scss';

interface FooterProps {
  className?: string;
}

export default function Footer(
  {
    className = '',
  }: FooterProps,
): JSX.Element {
  return (
    <>
      <section className="py-8 py-md-10 mt-12" id="contact-us" style={{ marginTop: 60 }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col d-flex flex-column align-items-center">
              <h2 className="text-center mw-md-50 mw-xxl-45 mb-6">
                Напишите нам
                <br />
                для запроса пробной версии:
              </h2>
              <p className="mb-0 h2">
                info@cadex-soft.ru
              </p>
            </div>
          </div>
        </div>
      </section>
      <footer className={`py-8 py-md-10 ${className}`}>
        <div className="container font-size-sm">
          <div className="row justify-content-start">
            <div className="col-12 col-md-6 col-lg-auto d-flex flex-column mb-4 mb-lg-0" style={{ fontSize: 16 }}>
              <p className="mb-2">Полное наименование Общество с ограниченной ответственностью «КАДЭКС-СОФТ»</p>
              <p className="mb-2">Сокращенное наименование ООО «КАДЭКС&#8209;СОФТ»</p>
              <p className="mb-2">ИНН / КПП  5260462648 / 732501001</p>
              <p className="mb-2">ОГРН 1195275020258</p>
            </div>
            <div className="col-12 col-md-6 col-lg-auto d-flex flex-column mb-4 mb-lg-0">
              <p className="mb-2">Адрес: 432017, <br /> Ульяновская обл., г.Ульяновск, <br /> пер. Комсомольский, д. 3А, оф. 209</p>

            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
