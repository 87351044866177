import React from 'react';

interface Info {
  name: string;
  description: {
    data: {
      childMarkdownRemark: {
        html: string;
      }
    }
  };
  logo?: string;
}

export interface VendorFormatInfoSectionProps {
  className?: string;
  info: Info;
  customTitle?: string;
}

export default function VendorFormatInfoSection(
  {
    className = '',
    info,
    customTitle,
  }: VendorFormatInfoSectionProps,
): JSX.Element {
  return (
    <section className={`py-8 py-md-10 ${className}`}>
      <div className="container">
        <div className="row justify-content-between">
          {info.logo && (
            <div className="col-12 col-lg-auto order-lg-last text-center mb-7 mb-lg-0 d-flex justify-content-lg-center align-items-center" data-aos="fade-left">
              <img
                src={info.logo}
                alt={`${info.name} Logo`}
                title={info.name}
                style={{ width: '12.5rem' }}
                loading="lazy"
              />
            </div>
          )}
          <div className="col-12 col-md-10 col-lg-9 col-xl-8" data-aos="fade-right">
            <h2 className="mb-7">
              {customTitle ?? `What is ${info.name}?`}
            </h2>
            <div dangerouslySetInnerHTML={{ __html: info.description.data.childMarkdownRemark.html }} />
          </div>
        </div>
      </div>
    </section>
  );
}
