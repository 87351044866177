import React from 'react';
import aos from 'aos';

interface AOSProps {
  children?: React.ReactNode,
}

export function initAOS(): void {
  aos.init({
    duration: 700,
    easing: 'ease-out-quad',
    once: true,
    startEvent: 'load',
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  aos.refresh(true);
}

export default function AOS({ children }: AOSProps): JSX.Element {
  React.useEffect(initAOS, []);

  return (
    <>{children}</>
  );
}
