/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import ProductCard from 'cadex-gatsby-theme/src/components/ProductCard';
import products from 'cadex-gatsby-theme/src/data/products';

import windowsIcon from 'cadex-gatsby-theme/src/img/tech/windows.svg';
import linuxIcon from 'cadex-gatsby-theme/src/img/tech/linux.svg';
import macOsIcon from 'cadex-gatsby-theme/src/img/tech/apple.svg';
import jsIcon from 'cadex-gatsby-theme/src/img/tech/js.svg';
import cppIcon from 'cadex-gatsby-theme/src/img/tech/cpp.svg';
import csharpIcon from 'cadex-gatsby-theme/src/img/tech/csharp.svg';
import pyIcon from 'cadex-gatsby-theme/src/img/tech/py.svg';
import javaIcon from 'cadex-gatsby-theme/src/img/tech/java.svg';
import gCloudIcon from 'cadex-gatsby-theme/src/img/tech/google_cloud.svg';
import azureIcon from 'cadex-gatsby-theme/src/img/tech/azure.svg';
import awsIcon from 'cadex-gatsby-theme/src/img/tech/aws.svg';

import './MainProductsSection.scss';
import BasicLink from 'cadex-gatsby-theme/src/components/BasicLink';

products.gui.name = 'Десктопное ПО';
products.gui.description = 'Десктопное ПО для просмотра, анализа и преобразования файлов 3D CAD.';
products.sdk.name = 'SDK';
products.sdk.description = 'C++, C#, Java и Python библиотеки для приложений и SaaS.';
products.cli.name = 'Cерверные решения';
products.cli.description = 'Cерверные решения для автоматизации конвертаций.';
products.customDev.name = 'Разработка “под ключ”';
products.customDev.description = 'Инженерное программное обеспечение на заказ и оптимизация существующих приложений.';

export interface MainProductsSectionProps {
  className?: string;
  noCustomDev?: boolean;
  title?: string;
  titleClassName?: string;
  SDKDescription?: string;
  noGui?: boolean;
}

interface IconRowProps {
  icons: {
    src: string;
    alt: string;
  }[];
}

function IconRow({ icons }: IconRowProps): JSX.Element {
  return (
    <div className="d-flex align-items-end flex-wrap mt-n2 main-products-icon-row">
      {icons.map((icon) => (
        <img
          src={icon.src}
          alt={icon.alt}
          title={icon.alt}
          key={icon.alt}
          className="mr-5 mb-2"
          loading="lazy"
        />
      ))}
    </div>
  );
}

export default function RuMainProductsSection(
  {
    className = '',
    noCustomDev,
    title,
    titleClassName,
    SDKDescription = 'C++, C#, Java и Python библиотеки для приложений и SaaS.',
    noGui,
  }: MainProductsSectionProps,
): JSX.Element {
  return (
    <section className={`${className} container`}>
      {title && (
        <div className="row justify-content-center">
          <h2 className={`text-center mb-6 mb-md-9 ${titleClassName}`}>
            {title}
          </h2>
        </div>
      )}
      <div className="row">

        {(!noGui) && (
          <div className="col-12 col-md-6 pb-4">
            <ProductCard
              vertical={!noCustomDev}
              name={products.gui.name}
              description={products.gui.description}
              color="green"
              subTitle="Для конечных пользователей"
              image={products.gui.logoUrl}
            >
              <IconRow
                icons={[
                  {
                    src: windowsIcon as unknown as string,
                    alt: 'Windows',
                  },
                  {
                    src: macOsIcon as unknown as string,
                    alt: 'MacOS',
                  },
                  {
                    src: linuxIcon as unknown as string,
                    alt: 'Linux',
                  },
                ]}
              />
            </ProductCard>
          </div>
        )}
        <div className={`col-12 col-md-6 ${noGui ? 'mx-auto' : ''} pb-4`}>
          <ProductCard
            vertical={!noCustomDev}
            name={products.sdk.name}
            description={SDKDescription}
            color="blue"
            subTitle="Для разработчиков"
            image={products.sdk.logoUrl}
          >
            <IconRow
              icons={[
                {
                  src: cppIcon as unknown as string,
                  alt: 'C++',
                },
                {
                  src: csharpIcon as unknown as string,
                  alt: 'C#',
                },
                {
                  src: javaIcon as unknown as string,
                  alt: 'Java',
                },
                {
                  src: jsIcon as unknown as string,
                  alt: 'JavaScript',
                },
                {
                  src: pyIcon as unknown as string,
                  alt: 'Python',
                },
              ]}
            />

          </ProductCard>

        </div>
        <div className="col-12 col-md-6 pb-4">
          <ProductCard
            vertical={!noCustomDev}
            name={products.cli.name}
            description={products.cli.description}
            color="blue"
            subTitle="Для разработчиков"
            image={products.cli.logoUrl}
          >
            <IconRow
              icons={[
                {
                  src: windowsIcon as unknown as string,
                  alt: 'Windows',
                },
                {
                  src: macOsIcon as unknown as string,
                  alt: 'MacOS',
                },
                {
                  src: linuxIcon as unknown as string,
                  alt: 'Linux',
                },
              ]}
            />
            <IconRow
              icons={[
                {
                  src: awsIcon as unknown as string,
                  alt: 'AWS',
                },
                {
                  src: gCloudIcon as unknown as string,
                  alt: 'Google Cloud',
                },
                {
                  src: azureIcon as unknown as string,
                  alt: 'Microsoft Azure',
                },
              ]}
            />
          </ProductCard>
        </div>
        <div className="col-12 col-md-6 pb-4">
          <ProductCard
            vertical={!noCustomDev}
            name={products.customDev.name}
            description={products.customDev.description}
            color="blue"
            smallPadding
            subTitle="Для бизнеса"
            image={products.cloud.logoUrl}
          >
            <ul className="text-black" style={{ listStyleType: '-' }}>
              <li className="">Интеграция с САПР</li>
              <li className="">3D визуализация и конвертация</li>
              <li className="">Генерация полигональных сеток</li>
              <li className="">Параллельные и облачные вычисления</li>
            </ul>
          </ProductCard>
        </div>
      </div>
      <div className="row justify-content-center py-6">
        <div className="d-flex flex-column flex-md-row">

          <BasicLink to="#contact-us" style={{ minWidth: 280 }} className="btn btn-primary flex-grow-1 flex-grow-md-0 mb-3 mb-md-0">Попробовать бесплатно</BasicLink>

        </div>
      </div>
    </section>
  );
}
