import React from 'react';
import { Link } from 'gatsby';

export interface BasicLinkProps {
  to: string;
  className?: string;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  target?: string;
  style?: React.CSSProperties;
  state?: Record<string, string>
}

export function getOffsetY(el: HTMLElement) {
  let y = 0;
  while (el) {
    y += el.offsetTop - el.scrollTop;
    if (el.offsetParent === null) {
      break;
    }
    else {
      // eslint-disable-next-line no-param-reassign
      el = el.offsetParent as unknown as HTMLElement;
    }
  }
  return y;
}
const onHashLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, to: string) => {
  const id = to.slice(1);
  const anchor = document.getElementById(id);
  if (anchor) {
    const offsetTop = getOffsetY(anchor);
    window.scroll({
      top: offsetTop + 1,
      behavior: 'smooth',
    });
    event.currentTarget.blur();
    event.preventDefault();
  }
};
export default function BasicLink(
  {
    to,
    className,
    children,
    onClick,
    target,
    style,
    state,
  }: BasicLinkProps,
): JSX.Element {
  if (to.startsWith('/')) {
    return (
      <Link to={to} onClick={onClick} state={state} className={className} style={style}>
        {children}
      </Link>
    );
  }
  if (to.startsWith('#')) {
    return (
      <a href={to} onClick={onClick ?? ((e) => onHashLinkClick(e, to))} className={className} style={style}>
        {children}
      </a>
    );
  }
  return (
    <a href={to} target={target} onClick={onClick} className={className} style={style}>
      {children}
    </a>
  );
}
