/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import BasicLink from 'cadex-gatsby-theme/src/components/BasicLink';
import FeaturesCarousel, { FeaturesCarouselProps } from 'cadex-gatsby-theme/src/components/products/FeaturesCarousel';

export interface ruUseCaseSolution {
  title: string;
  description: string;
  imageUrl: string;
  url?: string;
  // className?: string;
}

export interface UseCaseSolutionSectionProps {
  id?: string;
  className?: string;
  title?: string;
  items: FeaturesCarouselProps['slides'];
}

export default function UseCaseSolutionSection(
  {
    id,
    className = '',
    title = 'Сферы применения 3D и САПР данных',
    items,
  }: UseCaseSolutionSectionProps,
): JSX.Element {
  return (
    <section className={`py-8 py-md-10 ${className}`} id={id}>
      <div className="container">
        <FeaturesCarousel title={title} slides={items} />
        <div className="row justify-content-center pt-10">
          <div className="d-flex flex-column flex-md-row">

            <BasicLink to="#contact-us" style={{ minWidth: 280 }} className="btn btn-primary flex-grow-1 flex-grow-md-0 mb-3 mb-md-0">Попробовать бесплатно</BasicLink>

          </div>
        </div>

      </div>
    </section>
  );
}
