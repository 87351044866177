import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
// import loadable from '@loadable/component';

import SEO from 'cadex-gatsby-theme/src/components/SEO';
import ProductsLandingSection from 'cadex-gatsby-theme/src/components/ProductsLandingSection';

import AOS from 'cadex-gatsby-theme/src/components/AOS';

import { NumberOfSupportedFormatsSiteInfo } from 'cadex-gatsby-theme/src/utils/queries';

import ImportExportIcon from 'cadex-gatsby-theme/src/img/icons/import-export.svg';
import ExploreIcon from 'cadex-gatsby-theme/src/img/icons/explore.svg';
import VisualizeIcon from 'cadex-gatsby-theme/src/img/icons/visualize.svg';

import QualityIcon from 'cadex-gatsby-theme/src/img/icons/quality.svg';
import EaseOfUseIcon from 'cadex-gatsby-theme/src/img/icons/ease-of-use.svg';
import CostEffectivenessIcon from 'cadex-gatsby-theme/src/img/icons/cost-effectiveness.svg';
import VendorFormatInfoSection from 'cadex-gatsby-theme/src/components/VendorFormatInfoSection';
// import logo from '../img/logo.png';
import Footer from '../components/Footer';

// import { BlogQueryResult, blogQueryResultToBlogPostArray } from '../../content/blog/BlogPost';
import RuMainProductsSection from '../components/RuMainProductsSection';
import UseCaseSolutionSection from '../components/UseCaseSolutionSection';
import { ruUsecases } from '../data/ruUseCases';
import { ruFeatures } from '../data/ruFeatures';
import homeImage from '../img/photos/products.webp';
import './index.scss';

export default function LandingPage(): JSX.Element {
  const data = useStaticQuery<NumberOfSupportedFormatsSiteInfo>(graphql`
  query HomePageQuery {
    site {
      ...NumberOfSupportedFormats
    }
  }
  `);

  return (
    <>

      <SEO
        title="ООО «КАДЭКС-СОФТ»"
        noTitleTail
        fullNoRobots
        description="ООО «КАДЭКС-СОФТ» – российская IT-компания, предлагающая решения в области совместимости САПР данных, которые позволяют просматривать, конвертировать и анализировать 3D данные, строить сетки для инженерного анализа, изучать структуру, свойства и PMI загружаемых моделей. Наши продукты используются разработчиками инженерного ПО, инженерами и дизайнерами в производственных, строительных и промышленных компаниях."
        image={`https://cadex-soft.ru/${homeImage}`}
      >
        <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
      </SEO>

      <ProductsLandingSection
        title="Программное обеспечение для просмотра и конвертации 3D данных САПР на ПК, мобильных устройствах и онлайн"
        secondaryButton={{
          label: 'Попробовать бесплатно',
          link: '#contact-us',
        }}
        wideText
        bodyText={
          (
            <>
              Приложения для инженеров и дизайнеров.
              <br />
              Программные продукты для разработчиков 3D-приложений.
              <br />
              Приложения “под ключ” для бизнеса.
            </>
          )
        }
        imageUrl={homeImage}
        imageAlt="ПО ООО «КАДЭКС-СОФТ»"
        image={<StaticImage src="../img/photos/products.webp" alt="ПО ООО «КАДЭКС-СОФТ»" title="ПО ООО «КАДЭКС-СОФТ»" layout="constrained" width={720} loading="eager" />}
      />
      <VendorFormatInfoSection
        customTitle="О компании"
        info={{
          name: 'О компании',
          description: {
            data: {
              childMarkdownRemark: {
                html: '<p>ООО «КАДЭКС-СОФТ» – российская IT-компания, предлагающая решения в области совместимости САПР данных, которые позволяют просматривать, конвертировать и анализировать 3D данные, строить сетки для инженерного анализа, изучать структуру, свойства и PMI загружаемых моделей. Наши продукты используются разработчиками инженерного ПО, инженерами и дизайнерами в производственных, строительных и промышленных компаниях.</p>',
              },
            },
          },
        }}
      />
      <AOS>

        <div className="bg-light py-8 py-md-10">

          <RuMainProductsSection />
        </div>

        <section className="py-8 py-md-10">

          <div className="container">

            <div className="row">
              <div className="col-12 col-md-4 pr-xl-9 pr-xxl-11" data-aos="fade-up">
                <ImportExportIcon className="svg-icon" />
                <h3 className="mt-4">Импорт и экспорт</h3>
                <p className="mb-6 mb-md-0">
                  Чтение и запись .sldprt, .sldasm, .dwg, .stl, .iam, .3dxml и ещё более
                  {data.site.siteMetadata.supportedFormats.total} других 3D&nbsp;форматов.
                </p>
              </div>
              <div className="col-12 col-md-4 px-xl-8 px-xxl-9" data-aos="fade-up" data-aos-delay="50">
                <ExploreIcon className="svg-icon" />
                <h3 className="mt-4">Анализ</h3>
                <p className="mb-6 mb-md-0">
                  Детали и сборки, точная и полигональная геометрия, метаданные и PMI.
                </p>
              </div>
              <div className="col-12 col-md-4 pl-xl-9 pl-xxl-11" data-aos="fade-up" data-aos-delay="100">
                <VisualizeIcon className="svg-icon" />
                <h3 className="mt-4">Визуализация</h3>
                <p className="mb-0">
                  Генерация сетки, разные способы отображения, материалы и прозрачность, построение сечений
                  и видов с разнесёнными деталями.
                </p>
              </div>
              <div className="col-12 col-md-4 pr-xl-9 pr-xxl-11 mt-4" data-aos="fade-up">
                <QualityIcon className="svg-icon" />
                <h3 className="mt-4">Качество</h3>
                <p className="mb-6 mb-md-0">Автоматическое восстановление геометрии и патентованные технологии параллельных вычислений.</p>
                {/* <p className="mb-6 mb-md-0">
                Rapidly process large assemblies with our patented multi-core technologies.
              </p> */}
              </div>
              <div className="col-12 col-md-4 px-xl-8 px-xxl-9 mt-4" data-aos="fade-up" data-aos-delay="50">
                <EaseOfUseIcon className="svg-icon" />
                <h3 className="mt-4">Простота использования</h3>
                <p className="mb-6 mb-md-0">
                  Интуитивный интерфейс, структурированный API и исчерпывающая документация.
                </p>
              </div>
              <div className="col-12 col-md-4 pl-xl-9 pl-xxl-11 mt-4" data-aos="fade-up" data-aos-delay="300">
                <CostEffectivenessIcon className="svg-icon" />
                <h3 className="mt-4">Выгода</h3>
                <p className="mb-0">
                  Независимость от дорогостоящих САПР, гибкие модели лицензирования.
                </p>
              </div>
            </div>

          </div>

        </section>

        <UseCaseSolutionSection
          items={ruUsecases}
        />
        <UseCaseSolutionSection
          items={ruFeatures}
          title="Возможности работы с 3D и САПР данными"
        />

        <Footer className="pt-md-11" />

      </AOS>
    </>
  );
}
