/* eslint-disable import/prefer-default-export */
import { FeaturesCarouselProps } from 'cadex-gatsby-theme/src/components/products/FeaturesCarousel';
import cadCam from '../img/case-cards/16by10/cad-cam.png';
import bim from '../img/case-cards/16by10/bim.png';
import unity from '../img/case-cards/16by10/unity.png';
import web from '../img/case-cards/16by10/web.png';
import manufacturing from '../img/case-cards/16by10/manufacturing.png';
import print from '../img/case-cards/16by10/3dprint.webp';
import ecad from '../img/case-cards/16by10/ecad.webp';
import cae from '../img/case-cards/16by10/cae.png';

export const ruUsecases: FeaturesCarouselProps['slides'] = [
  {
    title: 'Машиностроительные САПР (CAD/CAM)',
    navTitle: 'Машиностроительные САПР (CAD/CAM)',
    bodyText: 'Интеграция 3D-данных повышает точность проектирования, снижает производственные затраты и расширяет функциональную совместимость приложений с PLM-системами и производственным оборудованием.',
    image: cadCam,
  },
  {
    title: 'Архитектурно-строительные САПР (BIM/AEC)',
    navTitle: 'Архитектурно-строительные САПР (BIM/AEC)',
    bodyText: 'Внедрение моделей из Revit, AutoCAD, Navisworks и других архитектурных САПР во все этапы проектирования, строительства и эксплуатации зданий позволяет контролировать конструкторские решения, обмениваться информацией об авторах, поставщиках и сроках, производить симуляцию объекта и создавать анимацию и графику для демонстрационных целей.',
    image: bim,
  },
  {
    title: 'AR, VR и Unity приложения',
    navTitle: 'AR, VR и Unity приложения',
    bodyText: 'Импорт моделей САПР в приложения с дополненной и виртуальной реальностью, а также в продукты на движке Unity обеспечивает быструю визуализацию данных, высококачественный рендеринг, а также точность и функциональность цифровых двойников.',
    image: unity,
  },
  {
    title: '3D веб-приложения',
    navTitle: '3D веб-приложения',
    bodyText: 'Доступность CAD и BIM данных в онлайн-приложениях позволяет создавать платформы для совместной работы над 3D моделями, онлайн-сервисы мелкосерийного производства деталей (MaaS), конфигураторы продуктов и онлайн-каталоги. Решение использует интерфейс WebGL и совместимо с three.js, React, Vue, Angular и другими технологиями.',
    image: web,
  },
  {
    title: 'Производство под заказ',
    navTitle: 'Производство под заказ',
    bodyText: 'Благодаря извлечению данных из 3D моделей можно создавать приложения для расчёта стоимости и сроков производства, онлайн-сервисы для заказа мелкосерийного производства, а также алгоритмы оптимизации операций с листовым металлом и с композитными материалами, аддитивного производства (3D-печать) и других технологических процессов.',
    image: manufacturing,
  },
  {
    title: '3D-печать',
    navTitle: '3D-печать',
    bodyText: 'Данные САПР могут быть конвертированы в STL, 3MF, OBJ, VRML и другие полигональные форматы, чтобы обеспечить пригодность моделей для 3D-печати без необходимости оптимизировать их вручную. На основе CAD-данных можно сгенерировать сетку, оценить стоимость и технологичность изделия, расчитать высоту слоёв, объём заполнения модели и выполнить другие подготовительные операции.',
    image: print,
  },
  {
    title: 'Автоматизация проектирования электронных устройств (ECAD)',
    navTitle: 'Автоматизация проектирования электронных устройств (ECAD)',
    bodyText: 'Совместимость данных из электротехнических (ECAD) и машиностроительных (MCAD) САПР влияет на  качество электронного изделия. Корректный импорт механических компонентов в ECAD-среду позволяет проверять соответствие корпуса и электрической схемы на всех этапах проектирования и быстро согласовывать изменения в проекте.',
    image: ecad,
  },
  {
    title: 'Моделирование и инженерный анализ (CAE)',
    navTitle: 'Моделирование и инженерный анализ (CAE)',
    bodyText: 'Импорт файлов САПР в CAE-среду даёт возможность точно предсказать поведения спроектированной системы с помощью FEA-, CFD- и CEM- моделирования. Для корректной работы FEA-решателя полигональная сетка генерируется из B-Rep модели с учетом геометрии, физических свойств и области применения, граничных условий и условий нагрузки и записывается в форматах, совместимых с Netgen, Abaqus, Fluent, OpenFOAM и другими вычислительными инструментами.',
    image: cae,
  },
];
