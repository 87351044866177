import React from 'react';
import CTAHugger from './CTAHugger';
import LinkWithFlatArrow from './LinkWithFlatArrow';
import SimpleTabbedCarousel from './SimpleTabbedCarousel';

export interface FeaturesCarouselSlideProps {
  title: string;
  navTitle?: string;
  bodyText: string;
  image: string;
  link?: {
    url: string;
    text: string;
  }
}

function FeaturesCarouselSlide(
  {
    title,
    bodyText,
    navTitle,
    link,
    image,
  }: FeaturesCarouselSlideProps,
): JSX.Element {
  return (
    <div className="row justify-content-md-between">
      <div className="col-12 col-md-5 order-md-last">
        <img loading="lazy" className="w-100" src={image} alt={navTitle} />
      </div>
      <div className="col-12 col-md-6">
        <h3 className="mb-4">{title}</h3>
        <p className="text-body mb-4">
          {bodyText}
        </p>

        {link && <LinkWithFlatArrow to={link.url}>{link.text}</LinkWithFlatArrow>}

      </div>

    </div>
  );
}

export interface FeaturesCarouselProps {
  className?: string;
  title: string;
  cta?: string;
  ctaLink?: string;
  id?: string;
  slides: FeaturesCarouselSlideProps[]
}

export default function FeaturesCarousel(
  {
    className = '',
    title,
    cta,
    ctaLink,
    id = 'key-features',
    slides,
  }: FeaturesCarouselProps,
): JSX.Element {
  return (
    <CTAHugger
      title={title}
      id={id}
      cta={cta}
      link={ctaLink}
      className={className}
    >
      <SimpleTabbedCarousel
        id="features-carousel"
        items={slides.map(((slide) => ({
          title: slide.navTitle,
          content: (<FeaturesCarouselSlide
            bodyText={slide.bodyText}
            image={slide.image}
            title={slide.title}
            link={slide.link}
            navTitle={slide.title}
          />),
        })))}
      />
    </CTAHugger>
  );
}
