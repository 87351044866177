import React from 'react';
import FlickityComponent from 'react-flickity-component';
import clsx from 'clsx';

import Flickity from '../Flickity';
import HoveredLogo from './HoveredLogo';

import RightArrow from '../../img/icons/carousel-right.svg';
import LeftArrow from '../../img/icons/carousel-left.svg';

import './SimpleTabbedCarousel.scss';

export interface SimpleTabbedCarouselItemData {
  title: React.ReactNode;
  logo?: string;
  bluedOutLogo?: string;
  content: React.ReactNode;
}

export interface SimpleTabbedCarouselProps {
  id: string;
  className?: string;
  innerWrapperclassName?: string;
  items: SimpleTabbedCarouselItemData[];
  withButtons?: boolean;
  withNav?: boolean;
  groupSlides?: boolean;
  buttonWrapperClassName?: string;
  draggable?: boolean;
}

interface ButtonsWrapperProps {
  flkty: FlickityComponent | undefined;
  children: React.ReactNode;
  active: boolean | undefined;
  buttonWrapperClassName?: string;
}

function ButtonsWrapper({
  active,
  children,
  flkty,
  buttonWrapperClassName = '',
}: ButtonsWrapperProps): JSX.Element {
  return (
    (active && flkty) ? (
      <div className={`carousel-grid-buttons-wrapper ${buttonWrapperClassName}`}>
        <button
          type="button"
          onClick={() => flkty.previous()}
          className="d-flex align-items-center pr-6"
        >
          <LeftArrow />
        </button>
        {children}
        <button
          type="button"
          onClick={() => flkty.next()}
          className="pl-6 d-flex align-items-center"
        >
          <RightArrow />
        </button>
      </div>
    )
      : (
        <>
          {children}
        </>
      )
  );
}

export default function SimpleTabbedCarousel(
  {
    id,
    className = '',
    items,
    withButtons,
    withNav = true,
    groupSlides,
    innerWrapperclassName = '',
    buttonWrapperClassName,
    draggable = true,
  }: SimpleTabbedCarouselProps,
): JSX.Element {
  const [flkty, setFlkty] = React.useState<FlickityComponent>();
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  React.useEffect(() => {
    if (!flkty) {
      return;
    }
    flkty.on('change', (index: number) => {
      setSelectedIndex(index);
    });
  });

  const onHeaderClicked = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    e.preventDefault();
    if (flkty && index !== selectedIndex) {
      flkty.select(index);
    }
  };

  return (
    <div id={id} className={`${className}`}>
      {withNav && (
        items[0].logo
          ? (
            <div className="d-flex justify-content-center justify-content-md-between mb-md-8 w-100 flex-wrap flex-md-no-wrap">
              {items.map((item, index) => {
                if (!(item.logo && item.bluedOutLogo)) {
                  // eslint-disable-next-line no-console
                  console.error(`${item.title} is missing logo`);
                  return null;
                }
                return (
                  <HoveredLogo
                    active={selectedIndex === index}
                    alt={item.title?.toString() || ''}
                    key={item.title?.toString()}
                    className="mb-4"
                    onClick={(e) => onHeaderClicked(e, index)}
                    bluedOutLogo={item.bluedOutLogo}
                    logo={item.logo}
                  />
                );
              })}
            </div>
          )
          : (
            <div className=" mb-6 mb-md-8 d-flex justify-content-center w-100 flex-wrap">
              {items.map((item, index) => (
                <button
                  type="button"
                  key={item.title?.toString()}
                  style={{ whiteSpace: 'nowrap' }}
                  className={clsx('btn-nav-new mx-2 mb-4', selectedIndex === index && 'active')}
                  onClick={(e) => onHeaderClicked(e, index)}
                >
                  {item.title}
                </button>
              ))}

            </div>
          ))}
      <ButtonsWrapper
        active={withButtons}
        flkty={flkty}
        buttonWrapperClassName={buttonWrapperClassName}
      >

        <div className={innerWrapperclassName}>
          <Flickity
            className={`blog-carousel ${id}-slider slider`}
            flickityRef={setFlkty}
            options={{
              wrapAround: true,
              pageDots: false,
              adaptiveHeight: false,
              setGallerySize: true,
              prevNextButtons: false,
              draggable,
              // cellAlign: 'left',
              // contain: true,
              groupCells: groupSlides ? 2 : undefined,
            }}
          >
            {items.map((item) => (
              <div key={`${JSON.stringify(item.title)}_content`} className="">
                <div>

                  {item.content}
                </div>
              </div>
            ))}
          </Flickity>
        </div>
      </ButtonsWrapper>
    </div>
  );
}
