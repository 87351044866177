import React from 'react';
import BasicLink from '../BasicLink';
import LinkWithFlatArrow from './LinkWithFlatArrow';

export interface CTAHuggerProps {
  className?: string;
  title: string;
  titleClassName?: string;
  link?: string;
  cta?: string;
  children: React.ReactNode;
  variant?: 'btn' | 'link'
  id?: string;
  ref?: React.MutableRefObject<HTMLElement | HTMLDivElement | null>;
}

export default function CTAHugger(
  {
    className = 'mt-12',
    cta,
    link,
    title,
    titleClassName = 'mb-9',
    children,
    variant = 'btn',
    id,
    ref,
  }: CTAHuggerProps,
): JSX.Element {
  return (
    <div className="container" id={id}>
      <section className={className} ref={ref}>
        <h2 className={`text-center ${titleClassName}`}>{title}</h2>
        {children}
        <div className="d-flex">
          {(link && cta) && (variant === 'btn'
            ? <BasicLink className="btn btn-primary small mt-9 mx-auto" to={link}>{cta}</BasicLink>
            : <LinkWithFlatArrow className="mt-9 mx-auto" to={link}>{cta}</LinkWithFlatArrow>)}

        </div>
      </section>
    </div>
  );
}
