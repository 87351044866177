import FlickityComponent, { FlickityOptions } from 'react-flickity-component';

const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

// import 'flickity-imagesloaded';
// import 'flickity-as-nav-for';
// import 'flickity-fade';\

export default class Flickity extends FlickityComponent {
  componentDidMount(): void {
    if (canUseDOM) {
      /* eslint-disable global-require */
      require('flickity');
      require('flickity-as-nav-for');
      require('flickity-fade');
    }
    if (super.componentDidMount) {
      super.componentDidMount();
    }
  }

  componentDidUpdate(prevProps: FlickityComponent['props'], prevState: FlickityComponent['state']): void {
    const { flickityReady } = this.state;

    // By default we consider content is static, so children has not been changed
    // to avoid unneeded flickity updating.
    const childrenNotChanged = true;

    const prevOptions = prevProps.options;
    const { options } = this.props;
    const prevOptionKeys = Object.keys(prevProps.options || {}) as Array<keyof FlickityOptions>;
    const optionNotChanged = prevOptions && options && prevOptionKeys.length === Object.keys(options).length
      && prevOptionKeys.every((key) => prevOptions[key] === options[key]);

    if (prevState.flickityReady && flickityReady
      && childrenNotChanged
      && optionNotChanged) {
      return;
    }
    if (super.componentDidUpdate) {
      super.componentDidUpdate(prevProps, prevState);
    }
  }
}
