import products from '../../cloud-functions/shared/data/products';

import GuiLogo from '../img/products/gui.webp';
import CloudLogo from '../img/products/cloud.webp';
import SDKLogo from '../img/products/sdk.webp';
import CLILogo from '../img/products/cli.webp';
import WtkLogo from '../img/products/wtk.webp';
import CloudApiLogo from '../img/products/cloud-api.webp';
import CustomDevLogo from '../img/products/custom-dev.webp';

products.gui.logoUrl = GuiLogo;
products.cloud.logoUrl = CloudLogo;
products.sdk.logoUrl = SDKLogo;
products.cli.logoUrl = CLILogo;
products.wtk.logoUrl = WtkLogo;
products.cloudApi.logoUrl = CloudApiLogo;
products.customDev.logoUrl = CustomDevLogo;

export default products;
